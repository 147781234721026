<template>
  <CCard>
    <CCardHeader>
      <div class="card-header-actions">
        <CButton variant="outline" color="success" @click="onReset" ref="reset-button" v-translate
                 translate-context="form" class="mr-2">Reset
        </CButton>
        <CButton color="primary" @click="onSave" ref="save-button">{{ save_text }}</CButton>
      </div>
    </CCardHeader>
    <CCardBody class="p-0">
      <CContainer fluid class="grid-stripped">
        <PFRow :label="$pgettext('event_template.label', 'Template name')">
          <CInput v-model="form.name" :placeholder="$pgettext('event_template.placeholder','Name')"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Allow profile event photo')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allows_profile_event_photo"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Allow photo watermark')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allows_photo_watermark"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Banner ADV + Link')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allows_advertisement"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Allow public photo and video')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="form.allows_public_photo_and_video"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Allow data collection form')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allows_form"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Allow checkin option')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allows_checkin_options"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Allow checkin popup')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allows_popup"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Allow event customization')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allows_event_customization"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Allow photo map')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allows_photo_map"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Allow Browse Photo')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allows_browse_photo"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Allow Multiple Galleries')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allows_multiple_galleries"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Enable E-Mail Scheduler')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.enable_scheduler"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Date type')">
          <PSelectKeyValue :options="dateTypes.options()" :value.sync="form.date_type"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'Share Mode')">
          <CSelect v-model="form.share_mode" :options="shareMode"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'HD Storage Type')">
          <CSelect v-model="form.hd_storage_type" :options="hdStorageTypes"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'HD Dashboard serving Type')">
          <CSelect v-model="form.hd_dashboard_serving_type" :options="hdServingTypes"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'HD WWW serving Type')">
          <CSelect v-model="form.hd_www_serving_type" :options="hdServingTypes"/>
        </PFRow>
        <PFRow :label="$pgettext('event_template.label', 'SD WWW serving Type')">
          <CSelect v-model="form.sd_www_serving_type" :options="sdServingTypes"/>
        </PFRow>
      </CContainer>
    </CCardBody>
  </CCard>

</template>

<script>
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import dateTypes from "@/domain/core/constant/dateTypes";
import PFRow from "@/domain/core/components/PFRow.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Form",
  components: {PFRow, PSelectKeyValue},
  props: {
    data: {
      type: Object,
      required: true
    },
    save_text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        name: '',
        allows_profile_event_photo: false,
        allows_photo_watermark: false,
        allows_advertisement: false,
        allows_public_photo_and_video: false,
        allows_form: false,
        allows_checkin_options: false,
        allows_event_customization: false,
        allows_popup: false,
        allows_photo_map: false,
        allows_browse_photo: false,
        allows_multiple_galleries: false,
        show_all_event_photo: false,
        enable_scheduler: false,
        date_type: 's',
        share_mode: 'link',
        hd_storage_type: 's',
        hd_dashboard_serving_type: 's',
        hd_www_serving_type: 's',
        sd_www_serving_type: 'f',
      },
    }
  },
  async mounted() {
    this.onReset()
    await Promise.all([
      this.fetchShareMode(),
      this.fetchHdServingTypes(),
      this.fetchHdStorageTypes(),
      this.fetchSDServingTypes()
    ])
  },
  emits: ['save'],
  computed: {
    dateTypes() {
      return dateTypes
    },
    ...mapGetters('core', ['shareMode', 'hdServingTypes', 'hdStorageTypes', 'sdServingTypes']),
  },
  methods: {
    ...mapActions('core', ['fetchShareMode', 'fetchHdStorageTypes', 'fetchHdServingTypes', 'fetchSDServingTypes']),
    onReset() {
      this.form = {...this.data}
    },
    async onSave() {
      this.$emit('save', this.form)
    },
  }
}
</script>